import { Button, Card } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Rajasthan = () => {
  return (
    <>
      <div style={{ textAlign: 'center', maxWidth: '100%', margin: '0 auto', backgroundColor: '#f9f9f9', padding: '20px', fontFamily: "'Bad Script', cursive" }}>  
        <img style={{ maxWidth: '100%', height: '350px', display: 'block', margin: '0 auto', width: '100%' }} src="images/rajasthan0.jpeg" alt=""/>
        <div style={{marginTop:'30px',marginBottom:'30px',backgroundColor:'orange',borderRadius:"50px",height:'50px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <h1 style={{ color: '#333' }}>Pune to Rajasthan Cab Service</h1>
        </div>
        <div className="newsletter" style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
          <div className="container">
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
              <Card className="col-md-4" style={{ maxWidth: '100%', marginBottom: '20px' }}>
                <img style={{ maxWidth: '100%', height: 'auto',width:'500px' }} src="images/rajasthan1.jpeg" alt="" className="img-responsive"/>
              </Card>
              <div className="col-md-8" style={{ textAlign: '' }}>
                <h1>Introduction</h1>
                <p>Book your Pune to Rajasthan round trip cab package at Just Rs. 12000 Including Toll. Shreenath-Krupa provides A/c, Non A/c, Economical, Sedan and Tempo Traveller</p>
                <p>Shreenath-Krupa Travels provide the best car service, especially for traveling from Pune to Rajasthan by Cab / Taxi and all you need to do is choose us for your travel. Book your tickets now at Shreenath-Krupa.com.</p>
                <p>Don’t hesitate to choose us for cab service as we are the best and we offer affordable and comfortable tour packages for our customers. The travel from Pune to Rajasthan will be very much enjoyable and you would be attended by our supporting staff in a leisurely way. We have excellent cab service with good condition for the Pune to Rajasthan trip with professionally well off trained drivers. You can choose both AC and Non AC cars of various models. Depending on your convenience and requirement you can choose the apt cab for you.</p>
              </div>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center' }}>
              <div className="col-md-8" style={{ textAlign: 'left' }}>
                <h1>Explore various tourist spots in Rajasthan</h1>
                <p>Rajasthan, also known as the "Land of Kings," is a vibrant state in India renowned for its rich history, majestic forts, and colorful culture. Some of the must-visit attractions in Rajasthan include Jaipur, Udaipur, Jaisalmer, Ajmer, and Mount Abu.</p>
                <p>Experience the grandeur and opulence of Rajasthan's palaces and forts while our cab service takes you on a mesmerizing journey through this enchanting state. Immerse yourself in the royal heritage and architectural splendor of Rajasthan.</p>
                <h1>Duration</h1>
                <p>The distance between Pune and Rajasthan varies depending on the destination within Rajasthan, but it's approximately 1000-1500 km. With our well-maintained cabs and experienced drivers, you can reach your desired destination in Rajasthan comfortably within 16 to 20 hours.</p>
              </div>
              <Card className="col-md-4" style={{ maxWidth: '100%', marginBottom: '20px' }}>
                <img style={{ maxWidth: '100%', height: 'auto',width:'500px' }} src="images/rajasthan2.jpeg" alt="" className="img-responsive"/>
              </Card>
            </div>
            <h1>Location</h1>
            <p>Rajasthan is located in the northwestern part of India and shares borders with Punjab, Haryana, Uttar Pradesh, Madhya Pradesh, and Gujarat. It is renowned for its desert landscapes, magnificent palaces, and vibrant culture, attracting tourists from around the world.</p>
            <h1>100% guaranteed super service</h1>
            <p>At Shreenath-Krupa Travels, customer satisfaction is our top priority. We are committed to providing a delightful travel experience to all our customers. With our reliable cab service, you can enjoy a hassle-free journey from Pune to Rajasthan.</p>
            <h1>How are we different?</h1>
            <p>Choose from our wide range of luxurious and comfortable cabs at affordable prices. Whether you prefer a sedan, hatchback, or SUV, we have the perfect vehicle to suit your travel needs. Our well-trained drivers ensure a safe and enjoyable ride, allowing you to relax and enjoy the scenic beauty of Rajasthan.</p>
            <h1>Book us now</h1>
            <p>Book your Pune to Rajasthan cab package today and embark on a memorable journey with Shreenath-Krupa Travels. Visit our website Shreenath-Krupa.com to make an online booking or contact us at +91 8485800099 for inquiries and assistance. Experience the convenience and comfort of traveling with us.</p>
            <b>Book your Pune to Rajasthan Cab Package Now!</b>
            <h1>Pune to Rajasthan Cab Service</h1>
            <b>Pune to Rajasthan cab service | Pune to Rajasthan taxi service | Book Pune to Rajasthan cab service | pune to Rajasthan taxi drop | pune to Rajasthan tour package | pune to Rajasthan cab rates | pune to Rajasthan cab price | pune to Rajasthan cabs | Pune to Rajasthan Cab Round Trip</b>
          </div>
        </div>   
        <div className="footer-agile" style={{ backgroundColor: '#333', color: '#fff', padding: '20px', marginTop: '20px', borderRadius: '5px', fontFamily: "'Bad Script', cursive" }}>
          <div className="container">
            {/* Footer content */}
            <Button sx={{color:'white',fontSize:'30px'}}><Link to="/#book">Book Now</Link></Button>
          </div>
        </div> 
      </div>
    </>
  );
};

export default Rajasthan;
